import * as React from "react";
import { PropertyAboutThisProperty as SharedUiPropertyAboutThisProperty } from "@shared-ui/lodging-property-details";
import { PropertyBexApiWrapper } from "components/shared/BexApiWrapper/PropertyBexApiWrapper";
import { UitkSpacing } from "@egds/react-core/spacing";
import { inject, observer } from "mobx-react";
import { PropertyAboutThisProps } from "./typings";
import { useExperiment } from "@shared-ui/experiment-context";

const WrappedPropertyAboutThis = PropertyBexApiWrapper(SharedUiPropertyAboutThisProperty);

export const PropertyAboutThis = inject("context")(
  observer(({ context, templateComponent }: PropertyAboutThisProps) => {
    if (!templateComponent) return null;
    const { displayThreshold, showBorder, enableExpando, columns } = templateComponent.config;
    const { exposure } = useExperiment("Dateless_PDP_Progressive_disclosure_Fully_collapsed_content_sections");
    const disableSectionSeparator = exposure.bucket === 1;

    const getSpacing = () => {
      if (showBorder && displayThreshold === 0) {
        return {
          small: { blockend: "two", inline: "three" },
          medium: { blockend: "two", inline: "unset" },
        };
      }
      if (showBorder) {
        return {
          small: { blockend: "six", inline: "three" },
          medium: { blockend: "six", inline: "unset" },
        };
      }
      if (disableSectionSeparator) {
        return "unset";
      }
      return { block: "three" };
    };

    return (
      <UitkSpacing margin={getSpacing()}>
        <section id="AboutThisProperty">
          <WrappedPropertyAboutThis
            context={context}
            columns={columns}
            displayThreshold={displayThreshold}
            isExpandoSection={enableExpando}
            disableSectionSeparator={disableSectionSeparator}
            showBorder={showBorder}
          />
        </section>
      </UitkSpacing>
    );
  })
);

export default PropertyAboutThis;
